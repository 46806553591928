import {
  Elm
} from '../newsfeed/Dashboard';

document.addEventListener('turbolinks:load', () => {
  const target = document.getElementById('dashboard-elm-container');
  if (target != null) {
    const categories = $(target).data('categories');
    const app = Elm.Dashboard.init({
      node: target,
      flags: {
        categories: categories
      }
    });

    let sendScrollEvents = false;

    app.ports.startSendingScrollEvents.subscribe(function () {
      sendScrollEvents = true;
    });

    app.ports.openURLInNewTab.subscribe(function (url) {
      window.open(url);
    });

    $(window).bind('scroll', function () {
      const documentHeight = $(document).height();
      const windowHeight = $(window).height();
      const scrollTop = $(window).scrollTop();
      const absPos = Math.abs(documentHeight - windowHeight - scrollTop);
      const scrollOffset = 1;
      const loadMore = absPos < scrollOffset;
      if (loadMore && sendScrollEvents) {
        app.ports.scroll.send(null);
        sendScrollEvents = false;
      }
    });
  }
});
